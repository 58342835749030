import { Component, Vue, Ref } from "vue-property-decorator";
import debounce from "lodash.debounce";
import { AxiosError } from "axios";
import { ApiError, ApiErrorResponse } from "@/types/api";
import { State } from "vuex-class";
import { checkEmail, checkPassword, isEmpty, checkTelegram } from "@helpers";
import { setUserProfileInfo } from "@/lib/auth";
import { AuthActions } from "@store/modules/auth/types";
import { ProfileActions, ProfileState } from "@store/modules/profile/types";
import { authModule, profileModule } from "@store/namespaces";
import { VForm } from "@/types/vuetify";
import { RootState } from "@store/types";
import API from "@api";

@Component
export default class SignUp extends Vue {
  @Ref("signUpForm") private readonly signUpFormRef!: VForm;
  @State("utmLabels") private readonly utmLabelsState!: RootState["utmLabels"];

  @authModule.Action("signUp") private signUpAction!: AuthActions["signUp"];
  @profileModule.State("status")
  private readonly profileStatus!: ProfileState["status"];
  @profileModule.Action("fetchProfile")
  private readonly fetchProfileAction!: ProfileActions["fetchProfile"];

  private firstName = "";
  private lastName = "";
  private phone = "";
  private telegram = "";
  private email = "";
  private password = "";
  private secondPassword = "";
  private loading = false;
  private promocode: {
    checking: boolean;
    value: string;
    invalid: boolean | null;
  } = {
    checking: false,
    value: "",
    invalid: null,
  };

  private get disabledSubmitForm() {
    return this.promocode.checking || this.promocode.invalid;
  }

  private get inviteCode() {
    const { invite } = this.$route.query;

    if (typeof invite === "string") {
      return invite;
    }
  }

  private get referralInviteCode() {
    const { referral } = this.$route.query;

    if (typeof referral === "string") {
      return referral;
    }
  }

  private get promocodeErrorMessages() {
    return this.promocode.invalid
      ? [this.$vuetify.lang.t("$vuetify.errors.invalid_promocode")]
      : [];
  }

  private get promoCode() {
    return this.promocode.value.trim().toLowerCase();
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      telegram: (v: string) =>
        checkTelegram(v) ||
        this.$vuetify.lang.t("$vuetify.errors.invalid_telegram_login"),
      password: (v: string) =>
        checkPassword(v) ||
        this.$vuetify.lang.t("$vuetify.errors.password.length"),
      mustMatchPassword: (v: string) =>
        this.password === v ||
        this.$vuetify.lang.t("$vuetify.errors.password.must_match"),
      email: (v: string) => {
        if (!checkEmail(v)) {
          return this.$vuetify.lang.t("$vuetify.errors.email.incorrect");
        }

        if (/\.ru$/i.test(v)) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.email.try_with_another_domain"
          );
        }

        return true;
      },
    };
  }

  private async signUp() {
    if (
      this.loading ||
      this.disabledSubmitForm ||
      !this.signUpFormRef.validate()
    )
      return;

    this.loading = true;

    try {
      const email = this.email.trim();

      await this.signUpAction({
        email,
        password: this.password.trim(),
        firstName: this.firstName.trim(),
        lastName: this.lastName.trim(),
        phone: this.phone.trim(),
        telegram: this.telegram.trim(),
        invite: this.inviteCode,
        referralInvite: this.referralInviteCode,
        language: this.$vuetify.lang.current,
        promocode:
          !this.promocode.invalid && this.promoCode
            ? this.promoCode
            : undefined,
        utm: this.utmLabelsState,
      });

      setUserProfileInfo({
        email: this.email,
      });

      await this.fetchProfileAction({ isSaveErrorStatus: true });
      this.loading = false;
    } catch (err) {
      const error = err as AxiosError<ApiErrorResponse>;
      const errorCode = error?.response?.data?.error;

      this.loading = false;

      if (errorCode === ApiError.ACCOUNT_EMAIL_NOT_VERIFIED) {
        this.$router.push({
          name: "confirm-email",
          query: {
            from: "sign-up",
          },
        });
      } else if (this.profileStatus === "disabled") {
        this.$router.push({ name: "blocked-account" });
      } else if (this.profileStatus === "not_verified") {
        this.$router.push({ name: "verify" });
      }
    }
  }

  private async checkPromocode() {
    if (this.promocode.checking) return;

    const promocode = this.promoCode;

    this.promocode.checking = true;

    try {
      await API.promocode.checkPromocode({ promocode });
      this.promocode.checking = false;
      this.promocode.invalid = false;
    } catch {
      this.promocode.checking = false;
      this.promocode.invalid = true;
    }
  }

  private mounted() {
    const onCheckPromoCode = debounce(() => {
      const promocode = this.promoCode;

      if (isEmpty(promocode)) {
        this.promocode.invalid = null;
        return;
      }

      this.checkPromocode().finally(() => {
        if (promocode.trim() !== this.promoCode) {
          onCheckPromoCode();
        }
      });
    }, 300);

    this.$watch(() => {
      return this.promoCode;
    }, onCheckPromoCode);

    this.$watch(
      () => {
        return this.$vuetify.lang.current;
      },
      () => {
        this.signUpFormRef.validate();
      }
    );

    this.$once("hook:beforeDestroy", () => {
      onCheckPromoCode.cancel();
    });
  }
}
